// worker
export const SET_SERVER_TIME = "SET_SERVER_TIME"
export const SET_SUB_TICKER_WORKER = "SET_SUB_TICKER_WORKER"
export const SET_UN_SUB_TICKER_WORKER = "SET_UN_SUB_TICKER_WORKER"
export const GET_SERVER_TIME = "GET_SERVER_TIME"

// SET
export const SET_LANG_LIST = "SET_LANG_LIST"

export const SET_START_PATH = "SET_START_PATH"


// gameControl
export const REQUEST_GAME_START = "REQUEST_GAME_START"
export const REQUEST_GAME_RESULT = "REQUEST_GAME_RESULT"
export const REQUEST_GAME_CANCEL = "REQUEST_GAME_CANCEL"
export const REQUEST_GAME_INFO = "REQUEST_GAME_INFO"
export const SET_GAME_RESULT = "SET_GAME_RESULT"
export const SET_GAME_CANCEL = "SET_GAME_CANCEL"
export const SET_GAME_INFO = "SET_GAME_INFO"
export const SET_GAME_START = "SET_GAME_START"
export const REQUEST_GAME_IS_ACTIVE = "REQUEST_GAME_IS_ACTIVE"
export const SET_GAME_IS_ACTIVE = "SET_GAME_IS_ACTIVE"
export const SET_ROUND_INFO = "SET_ROUND_INFO"

//suspend
export const REQUEST_SUSPEND = "REQUEST_SUSPEND"
export const SET_SUSPEND ="SET_SUSPEND"
export const REQUEST_FINISH_SUSPENDED = "REQUEST_FINISH_SUSPENDED"



//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

//loading

export const CHANGE_LOADING = 'CHANGE_LOADING'


// card
export const REQUEST_CARD = "REQUEST_CARD"
export const SET_CARD = "SET_CARD"
export const SET_CARD_LIST = "SET_CARD_LIST"
export const SET_CHANGE_CARD = "SET_CHANGE_CARD"

//timer
export const SET_TIMER = "SET_TIMER"

// winner
export const SET_WINNER = "SET_WINNER"

//dealer

export const REQUEST_SELECT_DEALER = "REQUEST_SELECT_DEALER"
export const SET_SELECT_DEALER = "SET_SELECT_DEALER"

