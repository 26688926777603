import React, {useEffect, useState} from "react";
import CardListItem from "@components/CardsList/CardListItem";
import classNames from "classnames";
import {playerEnum} from "@constants/Enums";

function CardList({cards, className, player, activeArea}) {
    const [cardsList, setCardsList] = useState([0,1,2])
    const [disabledCards, setDisabledCards] = useState(new Array(6).fill(true))

    useEffect(() => {
        if (playerEnum.playerA === player) {
            setCardsList([0, 1, 2])
        } else {
            setCardsList([3, 4, 5])
        }
    }, [player])

    useEffect(() => {
        let array = new Array(6).fill(true);
        const c = JSON.parse(JSON.stringify(cards))
        const middleIndex = 3;

        const firstHalf = c.splice(0, middleIndex).filter(card => card);
        const secondHalf = c.splice(-middleIndex).filter(card => card);
        if (firstHalf.length !== 0 ) {
            if (firstHalf.length < secondHalf.length) {
                firstHalf.forEach((i, index) => {
                    array[index] = false
                })
                secondHalf.forEach((i, index) => {
                    array[middleIndex + index] = false
                })
                array[firstHalf.length] = false
            } else if (firstHalf.length === secondHalf.length) {
                firstHalf.forEach((i, index) => {
                    array[index] = false
                })
                secondHalf.forEach((i, index) => {
                    array[middleIndex + index] = false
                })
                array[firstHalf.length ] = false
            } else {
                firstHalf.forEach((i, index) => {
                    array[index] = false
                })
                secondHalf.forEach((i, index) => {
                    array[middleIndex + index] = false
                })
                array[secondHalf.length + 3] = false
            }
        }
        setDisabledCards(array)
    }, [cards])


    return(
        <ul className={classNames("cards-list", className)}>
            {
                cardsList.map(cardNumber => (<CardListItem
                    cardNumber={cardNumber} key={cardNumber} cardList={cards}
                    disabled={(disabledCards[cardNumber] && cardNumber !==0) || !activeArea}
                />))
            }
        </ul>
    )
}

export default CardList