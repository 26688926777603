import {all, call} from 'redux-saga/effects';
import errorSaga from "./errorSaga";
import gameControlSaga from "./gameControl";
import cardSaga from "./cardSaga";
import suspendSaga from "./suspendSaga";
import dealerSaga from "./dealerSaga";

export default function* rootSaga() {
    yield all([
        call(errorSaga),
        call(gameControlSaga),
        call(cardSaga),
        call(suspendSaga),
        call(dealerSaga)
    ])
}