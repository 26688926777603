import React, {useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import cardBg from "@assets/img/cardBg.svg";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import {changeCard} from "@actions/cardAction";

function CardListItem({cardNumber, cardList, suspended, key,changeCard, disabled}) {

    const [showSelectModal, setShowSelectModal] = useState("");

    const toggleCustomSelect = (elemKey) => {
        if(suspended) {
            setShowSelectModal("" + elemKey)
        }
    }

    const selectCard = (value) => {
        changeCard(value, +showSelectModal)
        setShowSelectModal("")
    }

    return(<li className={classNames("cards-list-item", {disabled})} key={key}>
        <div className={classNames("flip-card", {active: cardList[cardNumber]})} onClick={() => toggleCustomSelect(cardNumber)}>
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <img src={cardBg} alt={cardBg} />
                </div>
                <div className="flip-card-back">
                    <i className={`cardIcon icon-${cardList[cardNumber]}`}/>
                </div>
            </div>
        </div>
        <CustomSelectModal visible={showSelectModal}
                           onCancel={() => setShowSelectModal("")}
                           onConfirm={selectCard}
        />
    </li>)
}
const mapStateToProps = (state) => {
    return {
        suspended: state.suspended
    }
}

const mapDispatchToProps = {
    changeCard
}

export default connect(mapStateToProps, mapDispatchToProps)(CardListItem)