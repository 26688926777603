import React, {useContext, useLayoutEffect, useState} from "react";
import {connect} from "react-redux";
import {Button} from "antd";
import {FormattedMessage} from "react-intl";
import CustomSelectModal from "@components/CustomSelectModal/CustomSelectModal";
import cardsEnum from "@constants/cards";
import {scanCard} from "@actions/cardAction";
import {getTimeFromTickerWorker} from "@actions/workerActions";
import {setErrorMsg} from "@actions/alertAction";
import suspended from "../../redux/reducers/suspendedReducer";
import {TimerContext} from "../../contexts/TimerContext";
import {playWarningSound} from "@services/soundServices";

function CustomSelect({scanCard, getTimeFromTickerWorker, setErrorMsg, suspended, cards, autoFocus}) {
    const [showSelectModal, setShowSelectModal] = useState(false)
    const duration = useContext(TimerContext)
    const selectCard = (value) => {
        if (duration > 3) {
            playWarningSound();
            setErrorMsg(duration !== undefined ? "bet_duration_is_not_over" : "round_is_not_started");
        } else {
            let code = ""
            try {
                cardsEnum.forEach(item => {
                    let cardName = item.CardName.slice(0, -1)
                    let suit = item.CName.split(" ")[2].toLowerCase()
                    if (value === cardName + suit) {

                        code = item.Code
                        throw 'Break'
                    }
                })
            } catch (e) {
                if (e !== 'Break') throw e
            }

            let existingCardIndex = cards.indexOf(value)

            if (existingCardIndex === -1) {
                setShowSelectModal(false)
                scanCard(code)
                getTimeFromTickerWorker()
            } else {
                setErrorMsg("card_already_opened")
            }
        }
    }

    useLayoutEffect(() => {
        autoFocus()
    }, [showSelectModal])

    return(
        <div>
            {
                suspended ? "" : <Button className="miniBtn"
                                           disabled={duration}
                                           onClick={() => setShowSelectModal(true)}>
                    <FormattedMessage id='select'/>
                </Button>
            }
            <CustomSelectModal visible={showSelectModal}
                               onCancel={() => setShowSelectModal(false)}
                               onConfirm={selectCard}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        suspended: state.suspended,
        cards: state.cards,
    }
}

const mapDispatchToProps = {
    scanCard,
    getTimeFromTickerWorker,
    setErrorMsg
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSelect)