import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_SUSPEND, SET_SUSPEND, REQUEST_FINISH_SUSPENDED
} from "@actions/actionTypes";
import {PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* requestSuspend() {
    const suspended =  yield call(PutResource, `/round/Suspend` );
    yield put({type: SET_SUSPEND, isSuspended: suspended.isSuspended })
}

function* finishSuspended({codes}) {
    const res = yield call(PostResource, `/round/changecards`,{codes})
}

function* suspendSaga() {
    yield takeLatest(REQUEST_SUSPEND,safe(onError, requestSuspend));
    yield takeLatest(REQUEST_FINISH_SUSPENDED,safe(onError, finishSuspended));
}



export default suspendSaga;