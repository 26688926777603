import {combineReducers} from "redux";
import settings from "./settingsReducer";
import langList from "./langListReducer";
import error from "./errorReducer";
import loading from "./loadingReducer"
import responseErrors from "./responseErrorsReducer";
import path from "./pathReducer";
import cards from "./cardsReducer";
import timer from "./timerReducer"
import winner from "./winnerReducer"
import gameIsActive from "./gameIsActiveReducer"
import suspended from "./suspendedReducer";
import gameInfo from "./gameInfoReducer"


const rootReducer = combineReducers({
    langList,
    error,
    loading,
    responseErrors,
    path,
    settings,
    cards,
    timer,
    winner,
    gameIsActive,
    suspended,
    gameInfo
});

export default rootReducer;
