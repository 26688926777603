import React, {useContext} from "react";
import {TimerContext} from "./../../contexts/TimerContext";

function Timer() {
    const duration = useContext(TimerContext)
    return (
        <div className="ticker">
            <div className="tickerArea">{duration > 0 && parseInt(duration / 1000)}</div>
        </div>)
}

export default Timer