import initialStore from "../initialStore";
import {SET_ROUND_INFO} from "@actions/actionTypes";

export default function gameInfoReducer(state = initialStore.gameInfo, action) {
    switch (action.type) {
        case SET_ROUND_INFO:
            return action.roundInfo
        default:
            return state;
    }
}
