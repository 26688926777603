import cardsEnum from "@constants/cards";

export function sortCardsForUI(codes) {
    const local = new Array(6).fill(undefined);
    let step = 0;
    for (let i = 0; i < codes.length; i++) {
        if (i % 2 === 0) {
            local[step] = codes[i];
        } else {
            local[3 + step] = codes[i];
            step++;
        }
    }

    return local
}

export function cardsIsEmpty(codes) {
    return codes.filter(code => code).length
}

export function sortCardsForBack(codes) {
    const local =[]
    let step = 0;
    for (let i = 0; i < codes.length; i++) {
        if (i % 2 === 0) {
            local[i] = codes[step];
        } else {
            local[i] = codes[3 + step];
            step++;
        }
    }

    return local
}

export function cardsNameToBarcode(cards) {
    const codes = []
    cards.forEach(card => {
        try {
            cardsEnum.forEach(item => {
                let cardName = item.CardName.slice(0, -1)
                let suit = item.CName.split(" ")[2].toLowerCase()
                if (card === cardName + suit) {

                    codes.push(item.Code)
                    throw 'Break'
                }
            })
        } catch (e) {
            if (e !== 'Break') throw e
        }
    })

    return codes
}