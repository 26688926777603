import {REQUEST_CARD, SET_CHANGE_CARD} from "@actions/actionTypes";

export function scanCard(code) {
    return dispatch => {
        dispatch({type: REQUEST_CARD, code})
    }
}

export function changeCard(card, index) {
    return dispatch => {
        dispatch({type: SET_CHANGE_CARD, card, index : index})
    }
}