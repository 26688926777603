import {
    REQUEST_SUSPEND,
    REQUEST_FINISH_SUSPENDED, SET_ERROR_MSG
} from "@actions/actionTypes";
import {sortCardsForBack, cardsNameToBarcode} from "@services/cardsService";

export function startSuspended() {
    return dispatch => {
        dispatch({type: REQUEST_SUSPEND})
    }
}

export function finishSuspended(intl) {
    return (dispatch, getStore ) => {
        const cards = getStore().cards
        const sortedCards =  sortCardsForBack(cards)

        const duplicates = sortedCards.filter((item, index) => item && sortedCards.indexOf(item) !== index)
        if (duplicates.length) {
            dispatch({type: SET_ERROR_MSG, data: {type: 'error', messages: intl.formatMessage({id: "there_are_duplicate_cards", },{value: duplicates[0].toString()}) }});

        } else {
            const codes = cardsNameToBarcode(sortedCards)
            dispatch({type: REQUEST_FINISH_SUSPENDED, codes})
        }
    }
}
