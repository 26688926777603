import {SET_SUSPEND} from "@actions/actionTypes";

function suspendedReducer(state = false, action) {
    switch (action.type) {
        case SET_SUSPEND:
            return action.isSuspended;
        default:
            return state;
    }
}

export default suspendedReducer;