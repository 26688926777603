import React, {useEffect, useState} from 'react'
import {IntlProvider} from "react-intl";
import {connect, Provider as ReduxProvider} from "react-redux";
import MainPage from './pages/contents/MainPage'
import translations from './translations'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {LanguageCode} from "./constants/Enums";
import "./assets/sass/main.sass";
import './App.sass';
import routes from "./routes";
import ResponseObserver from "./hooks/ResponseObserver";
import {TimerContext} from "./contexts/TimerContext";
import {subscribeTickerWorker, unSubscribeTickerWorker} from "@actions/workerActions";

function App({lang, subscribeTickerWorker, unSubscribeTickerWorker, suspended}) {
    const [betDuration, setBetDuration] = useState(0);

    useEffect(() => {
        function getServerTime(e) {
            const time = JSON.parse(e.data);
            setBetDuration(time.data)
        }

        subscribeTickerWorker(getServerTime);
        if (suspended) {
            unSubscribeTickerWorker(getServerTime)
            setBetDuration(0)
        }
        return () => {
            unSubscribeTickerWorker(getServerTime)
        }
    }, [suspended])
    return (
        <IntlProvider
            locale={LanguageCode[lang]}
            messages={translations[LanguageCode[lang].toLowerCase()] ? translations[LanguageCode[lang].toLowerCase()] : {}}
            defaultLocale="EN">
            <Router>
                <div>
                    <Switch>
                        <ResponseObserver>
                            <TimerContext.Provider value={betDuration}>
                                <MainPage/>
                            </TimerContext.Provider>

                        </ResponseObserver>
                        <Route exact path="/" render={() => <Redirect to={routes[0].path}/>}/>
                    </Switch>
                </div>
            </Router>
        </IntlProvider>
    );
}

function mapStateToProps(state) {
    return {
        lang: state.settings.lang.id,
        suspended: state.suspended
    };
}

const mapDispatchToProps = {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
