export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const cardName = [
     "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"
]

export const CardsSuit = {
    1: "spades",
    2: "hearts",
    3: "clubs",
    4: "diamonds"
}

export const playerEnum = {
    0: "playerA",
    1: "playerB",
    "playerA": 0,
    "playerB": 1
}

export const winnerEnum = {
    0: "none",
    1: "playerA",
    2: "playerB",
    3: "draw",
    "none": 0,
    "playerA": 1,
    "playerB": 2,
    "draw": 3
}