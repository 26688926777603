import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {gameCancel, gameStart, gameResult, gameInfo, gameIsActive} from "@actions/gameControl";
import {startSuspended, finishSuspended} from "@actions/suspendAction"
import {FormattedMessage, injectIntl} from "react-intl";
import {Button, Space, Row, Col, Switch} from "antd";
import CardList from "@components/CardsList/CardsList";
import {scanCard} from "@actions/cardAction";
import Timer from "@components/Timer/Timer";
import classNames from "classnames";
import {winnerEnum, playerEnum} from "@constants/Enums";
import CustomSelect from "@components/CustomSelect/customSelect";
import {cardsIsEmpty} from "@services/cardsService";
import Scanner from "@components/Scanner/Scanner";

function GameControl({
                         gameCancel,
                         gameStart,
                         gameResult,
                         gameInfo,
                         loading,
                         cards,
                         scanCard,
                         timer,
                         winner,
                         gameIsActive,
                         isActive,
                         suspended,
                         startSuspended,
                         finishSuspended,
                         intl
                     }) {
    const [activeArea, setActiveArea] = useState("")
    const [online, setOnline] = useState(navigator.onLine);
    const [activeCards, setActiveCards] = useState(0)
    const timeOut = useRef([])

    useEffect(() => {
        function enable(e) {
            setOnline(true)
        }

        function disable(e) {
            setOnline(false)
        }

        window.addEventListener('online', enable);
        window.addEventListener('offline', disable);

        return () => {
            window.removeEventListener('online', enable);
            window.removeEventListener('offline', disable);
        }
    }, [])

    useEffect(() => {
        const activeList = cardsIsEmpty(cards)
        setActiveCards(activeList)
        if (timer.timerEnd || activeList || suspended) {
            setActiveArea(activeList % 2 !== 0 ? "B" : "A")
        } else {
            setActiveArea("")
        }
    }, [timer, cards])


    useEffect(() => {
        if (online) {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
            gameInfo()
        }
    }, [online])

    useEffect(() => {
        if (!loading._game_info && online) {
            timeOut.current.push(setInterval(() => {
                gameInfo()
            }, 1000))
        }
        return () => {
            timeOut.current.forEach(time => clearInterval(time))
            timeOut.current = []
        }
    }, [loading._game_info])


    const autoFocus = () => {
        window.focus();
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }
    return (<div>
        <div className="titleArea">
            <div className="titleArea--title"><FormattedMessage id='game_control'/></div>
            <div className="titleArea--BtnGroup">
                <Switch loading={loading._game_is_active}
                        onClick={() => gameIsActive(!isActive)}
                        disabled={activeArea}
                        checked={isActive}/>
            </div>
        </div>
        <div className="game-control">
            <Scanner />
            {isActive ?
                <>
                    <Row className="game-control-row" justify="space-between" align="center">
                        <Col span={10}
                             className={classNames("game-control-col", {active: activeArea === "A"})}>
                            {
                                winner === winnerEnum.playerA || winner === winnerEnum.draw ?
                                    <h2 className={classNames("winner", {draw: winner === winnerEnum.draw})}>
                                        <FormattedMessage id={winner === winnerEnum.draw ? "draw" : "winner"}/>
                                    </h2> : ""
                            }

                            <div className="title">A</div>
                            <CardList cards={cards} className="reverse" player={playerEnum.playerA} activeArea={activeArea}/>
                        </Col>
                        <div className="custom-control">
                            <Timer timer={timer}
                                   scanCard={scanCard}
                            />
                            {
                                activeArea && activeCards !== 6 ?
                                    <CustomSelect autoFocus={autoFocus}/>
                                    : ""
                            }

                        </div>
                        <Col span={10} className={classNames("game-control-col", {active: activeArea === "B"})}>
                            {
                                winner === winnerEnum.playerB || winner === winnerEnum.draw ?
                                    <h2 className={classNames("winner", {draw: winner === winnerEnum.draw})}>
                                        <FormattedMessage id={winner === winnerEnum.draw ? "draw" : "winner"}/>
                                    </h2> : ""
                            }
                            <div className="title">B</div>
                            <CardList cards={cards} player={playerEnum.playerB} activeArea={activeArea}/>
                        </Col>
                    </Row>
                    <Row justify="space-between" align="center">
                        <Col span={8} className="control-buttons">
                            {
                                activeArea ?
                                    <Button loading={loading._game_start || loading._game_result || loading._game_cancel}
                                            disabled={!activeArea || suspended}
                                            className="miniBtn"
                                            size="large"
                                            onClick={() => startSuspended()}>
                                        <FormattedMessage id='change_cards'/>
                                    </Button> : ""
                            }

                        </Col>
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    suspended ?
                                        <Button
                                            className="miniBtn"
                                            size="large"
                                            onClick={() => finishSuspended(intl)}>
                                            <FormattedMessage id='submit'/>
                                        </Button> :
                                        <>
                                            {
                                                !activeArea ?
                                                    <Button loading={loading._game_start}
                                                            disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                            className="miniBtn"
                                                            size="large"
                                                            onClick={gameStart}>
                                                        <FormattedMessage id='start'/>
                                                    </Button>
                                                    : ""
                                            }
                                            {
                                                activeCards === 6 ?
                                                    <Button loading={loading._game_result}
                                                            disabled={loading._game_result || loading._game_start || loading._game_cancel}
                                                            className="miniBtn"
                                                            size="large"
                                                            onClick={gameResult}>
                                                        <FormattedMessage id='result'/>
                                                    </Button>
                                                    :""
                                            }

                                        </>
                                }

                            </Space>
                        </Col>
                        <Col span={8} className="control-buttons">
                            <Space>
                                {
                                    suspended ||  !activeArea  ? "" :
                                        <Button loading={loading._game_cancel}
                                                disabled={loading._game_result || loading._game_start || loading._game_cancel || !activeArea}
                                                className="miniBtn"
                                                size="large"
                                                onClick={gameCancel}>
                                            <FormattedMessage id='cancel'/>
                                        </Button>
                                }

                            </Space>
                        </Col>
                    </Row>
                </> :
                <div className="message">
                    <FormattedMessage id="table_is_not_active"/>
                </div>}


        </div>


    </div>)
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        cards: state.cards,
        timer: state.timer,
        winner: state.winner,
        isActive: state.gameIsActive,
        suspended: state.suspended
    }
}

const mapDispatchToProps = {
    gameCancel,
    gameStart,
    gameResult,
    scanCard,
    gameInfo,
    gameIsActive,
    startSuspended,
    finishSuspended
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(GameControl))