import {
    SET_CARD,
    SET_CARD_LIST,
    SET_CHANGE_CARD
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {CardsSuit} from "@constants/Enums"
import {sortCardsForUI, cardsIsEmpty} from "@services/cardsService";

export default function cardsReducer(state = initialStore.cards, action) {
    switch (action.type) {
        // case SET_CARD:
        //     const updateCards = JSON.parse(JSON.stringify(state))
        //     const index = cards.findIndex(card => card.Code === action.code)
        //     if (index > -1) {
        //         let cardName = cards[index].CardName.slice(0, -1)
        //         let suit = cards[index].CName.split(" ")[2]
        //         updateCards[action.index] = (cardName + suit.toLowerCase())
        //     }
        //     return updateCards
        case SET_CARD_LIST:
            const codes = action.codes.map(item => {
                if(item) {
                    const cardItem = item.split(",")
                    return  cardItem[0] + CardsSuit[cardItem[1]]
                }
            });
            const cardsList = sortCardsForUI(codes)

            if (action.isSuspended) {
                if(cardsIsEmpty(state)) {
                    return state
                } else {
                    return cardsList
                }
            } else {
                return  cardsList
            }
        case SET_CHANGE_CARD:
            const initialCards = new Array(6).fill(undefined)
            const cards = initialCards.map((card,index) =>  state[index] ? state[index] : undefined )

            cards[action.index] = action.card
            return cards
        default:
            return state;
    }
}